import React, { Component } from 'react';
import { isEqual } from 'lodash';

import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import {
  Grid, Typography, Chip,
  List, ListItemText, ListItemButton,
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  borderRight: 0,
  borderBottom: 0,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default class CategoryCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: null
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.onlineBookings) {
      return true
    } else {
      if (isEqual(this.props.services, nextProps.services) && this.props.expanded === nextProps.expanded) {
        return false
      } else {
        return true
      }
    }
  }

  handleSelectService(serviceId, index) {
    this.setState({
      selectedIndex: index
    });

    this.props.handleSelectService(serviceId);
  }

  renderServices() {
    if (this.props.services.length === 0) {
      return <p>There are no services.</p>
    } else {
      return (
        <List style={{ width: '100%' }}>
          {this.renderService()}
        </List>
      )
    }
  }

  renderServiceTags(tagIds) {
    return tagIds.map(tagId => {
      const tag = this.props.serviceTags.find(tag => tag._id === tagId);

      return (
        <Grid item key={tag._id}>
          <Chip
            label={tag.tagName}
            style={{ backgroundColor: tag.tagColor, color: tag.textColor }}
            size="small"
          />
        </Grid>
      )
    })
  }

  renderService() {
    return this.props.services.map((service, index) => {
      return (
        <ListItemButton
          key={service._id}
          dense
          onClick={() => this.props.handleSelectService(service._id)}
        >
          <ListItemText
            primary={this.props.onlineBookings === true && service.onlineBookingName.trim() !== '' ? service.onlineBookingName : service.name}
            secondaryTypographyProps={{ component: 'div' }}
            secondary={this.props.onlineBookings === true &&
              <>
                {service.onlineBookingDescription !== undefined && service.onlineBookingDescription.trim() !== '' &&
                  <Typography
                    component="span"
                    variant="caption"
                    color="textPrimary"
                    style={{ display: 'block' }}
                  >
                    <i>{service.onlineBookingDescription}</i>
                  </Typography>
                }
                {service.tagIds !== undefined && service.tagIds.length > 0 &&
                  <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    {this.renderServiceTags(service.tagIds)}
                  </Grid>
                }
              </>
            }
          />
          {this.props.selectedServices.find(s => s._id === service._id) !== undefined && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
        </ListItemButton>
      )
    })
  }

  renderBundle() {
    return this.props.bundles.map((bundle, index) => {
      return (
        <ListItemButton
          key={bundle._id}
          dense
          onClick={() => this.props.handleSelectBundle(bundle)}
        >
          <ListItemText
            primary={
              <Typography variant="body2">{bundle.bundleName} <Chip icon={<Icon icon="fluent:box-multiple-20-regular" />} label="Bundle" size="small" component="span" /></Typography>
            }
            secondaryTypographyProps={{ component: 'div' }}
            secondary={bundle.description !== undefined && bundle.description !== '' &&
              <>
                <Typography
                  component="span"
                  variant="caption"
                  color="textPrimary"
                  style={{ display: 'block' }}
                >
                  <i>{bundle.description}</i>
                </Typography>
                {bundle.tagIds !== undefined && bundle.tagIds.length > 0 &&
                  <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    {this.renderServiceTags(bundle.tagIds)}
                  </Grid>
                }
              </>
            }
          />
          {this.props.selectedServices.find(s => s.bundleName === bundle.bundleName) !== undefined && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
        </ListItemButton>
      )
    })
  }

  render() {
    return (
      <Accordion
        variant="outlined"
        expanded={this.props.expanded}
        onChange={this.props.onExpandChange}
        TransitionProps={{
          style: { maxHeight: 300, overflowY: 'scroll' },
          unmountOnExit: true,
          mountOnEnter: true
        }}
      >
        <AccordionSummary>
          <Typography variant="subtitle2">{this.props.category.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {this.renderService()}
            {this.renderBundle()}
          </List>
        </AccordionDetails>
      </Accordion>
    )
  }
}